<template>
  <v-menu max-width="400" v-model="menu" :close-on-content-click="false" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text class="text-normal" v-bind="attrs" v-on="on">
        {{username}}
        <v-icon v-text="'mdi-account'" dark right />
      </v-btn>
    </template>
    <v-card width="300">
        <Loader :loading="loading" />
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar
      color="primary"
      size="62"
    >
      <span class="white--text text-h5">{{username.length > 0 ? username[0].toUpperCase() : 'N'}}</span>
    </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{username}}</v-list-item-title>
              <v-list-item-subtitle v-if="roles.length > 0" class="text-capitalize">
                <span v-for="(rol, i) in roles" :key="i">{{rol}}<span v-if="i !== roles.length -1">, </span> </span>
                </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      <slot class="text-center" name="configureMenu" />
      <v-divider></v-divider>
      <v-card-actions>
        <span color="text--white" class="text-caption"> v{{ version }} </span>
        <v-spacer></v-spacer>
        <slot class="text-center" name="exit" />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { BasicBtn } from "@/components";

const props = {
  username: {
    default: "",
    type:String,
  },
  roles: {
    default: [],
    type: Array,
  },
  version: {
    default: "0",
    type: String,
  },
  img: {
    default: "",
    type: String,
  },
   loading: {
    default: false,
    type: Boolean,
  },
};

export default {
  components: {
    BasicBtn,
  },
  data: () => ({
    menu: false,
    profile: require("@/assets/images/logo_c.png"),
  }),

  props: props,
};
</script>

<style scoped>
.text-normal {
  text-transform: none !important;
}
</style>
