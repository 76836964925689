let namesReport = {
  'Reporte de rotación': 'Reporte de Rotación',
  'Vacaciones': 'Reporte de Vacaciones',
  'Aniversarios': 'Reporte de Aniversarios',
  'Faltas': 'Reporte de Faltas',
  'Incapacidades': 'Reporte de Incapacidades',
  'Tiempo extra': 'Reporte de Tiempo Extra',
  'Registros': 'Reporte de Registros',
  'Reporte de personal': 'Reporte de Personal',
};

export default namesReport;
