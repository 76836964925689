import { render, staticRenderFns } from "./dialogForm.vue?vue&type=template&id=25cb8fa3&scoped=true&"
import script from "./dialogForm.vue?vue&type=script&lang=js&"
export * from "./dialogForm.vue?vue&type=script&lang=js&"
import style0 from "./dialogForm.vue?vue&type=style&index=0&id=25cb8fa3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25cb8fa3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardActions,VCardText,VContainer,VDialog,VSpacer,VToolbar,VToolbarTitle})
