<template>
  <div>
    <v-navigation-drawer v-model="openAux" absolute temporary right class="grey lighten-5" :stateless="dialog" width="500">
      <div class="header-style pa-6">
        <v-row>
          <v-col cols="10">
            <v-toolbar-title class="white--text"> Notificaciones </v-toolbar-title>
          </v-col>
          <v-col cols="2">
            <v-btn fab color="white" bottom x-small dense outlined @click="fillterSection = !fillterSection">
              <v-icon>{{ fillterSection ? "mdi-close" : "mdi-magnify" }}</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" v-if="fillterSection">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Buscar" clearable dense outlined hide-details v-on:input="orderList()" dark></v-text-field>
              </v-col>
              <v-col cols="12">
                <datePicker v-model="begin" label="Fecha inicio" offYear hideDetails offLimit v-on:input="orderList()" dark></datePicker>
              </v-col>
              <v-col cols="12">
                <datePicker v-model="end" label="Fecha fin" offYear hideDetails offLimit v-on:input="orderList()"  dark></datePicker>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <v-list two-line>
        <v-list-item-group active-class="primary--text">
          <template v-for="(item, index) in itemsAuxUnread">
            <v-list-item :key="'unread-' + index">
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <h4 class="font-weight-medium primary--text" v-text="item.titulo"></h4>
                  <h5 class="text--primary font-weight-regular " v-html="item.subtitulo"></h5>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text v-text="dateFormat(item.fecha_creacion)"></v-list-item-action-text>
                  <v-btn fab x-small elevation="1" @click="$emit('clicked-read', item.idnotificacion)">
                    <v-icon>mdi-check</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider :key="'divUnread-' + index" light></v-divider>
          </template>
          <template v-for="(item, index) in itemsAuxRead">
            <v-list-item :key="'read-' + index">
              <template v-slot:default="{ active }">
                <v-list-item-content>
                  <h4 class="font-weight-medium primary--text" v-text="item.titulo"></h4>
                  <h5 class="text--primary font-weight-regular " v-html="item.subtitulo"></h5>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text v-text="dateFormat(item.fecha_creacion)"></v-list-item-action-text>
                  <v-btn text fab x-small elevation="0">
                    <v-icon color="blue">mdi-check</v-icon>
                  </v-btn>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider v-if="index < itemsAuxRead.length - 1" :key="'divRead-' + index" light></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import datePicker from "@/components/form/datePicker.vue";
import moment from "moment";
import _ from "lodash";

const props = {
  open: {
    default: false,
    type: Boolean,
  },
  dialog: {
    default: false,
    type: Boolean,
  },
  items: {
    default: {
      no_leidos: [],
      leidos: []
    },
    type: Object,
  },
};

export default {
  name: "Notification",
  components: {
    datePicker,
  },
  data: () => ({
    search: "",
    begin: null,
    end: null,
    openAux: false,
    fillterSection: false,
    itemsAuxUnread: {},
    itemsAuxRead: {},
  }),
  watch: {
    fillterSection(value) {
      this.search = ""
      this.begin = null
      this.end = null
      this.orderList()
    },
    items() {
      this.itemsAuxUnread = JSON.parse(JSON.stringify(this.items.no_leidos))
      this.itemsAuxRead = JSON.parse(JSON.stringify(this.items.leidos))
    },
    open(value) {
      this.openAux = value
    },
    openAux(value) {
      this.$emit('update:open', value)
    }
  },
  mounted() {
    this.openAux = this.open;
  },
  methods: {
    async orderList() {
      let str = (this.search) ? this.search : ''
      let begin = this.begin
      let end = (this.end) ? this.end + ' 23:59' : this.end
      this.itemsAuxUnread = _.filter(this.items.no_leidos, function (o) {
        return (begin ? new Date(moment(o.fecha_creacion).local()) >= new Date(begin) : true) && (end ? new Date(moment(o.fecha_creacion).local()) <= new Date(end) : true) && o.titulo.toLowerCase().indexOf(str.toLowerCase()) >= 0
      })
      this.itemsAuxRead = _.filter(this.items.leidos, function (o) {
        return (begin ? new Date(moment(o.fecha_creacion).local()) >= new Date(begin) : true) && (end ? new Date(moment(o.fecha_creacion).local()) <= new Date(end) : true) && o.titulo.toLowerCase().indexOf(str.toLowerCase()) >= 0
      })
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY")
    },
  },
  props: props,
};
</script>

<style scoped>
  .header-style {
    background-color: #6c1414;
  }
</style>
