<template>
  <v-menu v-model="open" :close-on-content-click="false" offset-x>
    <template v-slot:activator="{ on, attrs }">
      <div dark v-bind="attrs" v-on="on">

        {{ data[id].eventos[day] ? data[id].eventos[day][data[id].eventos[day].length - 1] ? data[id].eventos[day][data[id].eventos[day].length - 1].tipo : "" : "" }}
        <slot></slot>
      </div>
    </template>

    <v-card class="pa-3">
      <h4>Eventos</h4>
      <ol>
        <li v-for="(norepeat, index) in data[id].eventos[day]" :key="index">
          {{ data[id] ? norepeat.tipo : "" }}
        </li>
      </ol>
    </v-card>
  </v-menu>
</template>

<script>
const props = {
  data: {
    default: {},
    type: Object,
  },
  id: {
    default: 0,
    type: Number,
  },
  day: {
    default: "",
    type: String,
  },
};

export default {
  data: () => ({
    open: false,
  }),

  props: props,
};
</script>

<style scoped></style>
