import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.min.css";

Vue.use(Vuetify);

const opts = {
  theme: {
    options: {
      customProperties: true,
    },
    icons: {
      iconfont: 'mdiSvg',
    },
    themes: {
      light: {
        primary: "#6c1414",
        secondary: "#000",
        disabled: "#E0E0E0",
      },
    },
  },
};

export default new Vuetify(opts);
