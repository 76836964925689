<template>
  <v-breadcrumbs :items="actualPage">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item :href="item.path">
        <v-icon color="primary" v-text="item.icon" left />
        <span v-text="item.title" />
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
const props = {};

export default {
  name: "breadcrumb",
  data: () => ({
    items: [
      {
        icon: "mdi-file-document-edit-outline",
        title: "Solicitudes",
        children: [
          { icon: "mdi-file-document-plus-outline", title: "Nueva solicitud", path: "/nueva-solicitud-empleados" },
          { icon: "mdi-clipboard-text-clock-outline", title: "Ver todas las solicitudes", path: "/solicitud-empleados" },
        ],
      },
      {
        icon: "mdi-account-cog",
        title: "Administración de recursos humanos",
        children: [
          { icon: "mdi-clock", title: "Horarios", path: "/horarios" },
          { icon: "mdi-account-group", title: "Empleados", path: "/usuario" },
          { icon: "mdi-account-tie", title: "Puesto", path: "/puesto" },
          { icon: "mdi-briefcase-outline", title: "Departamento", path: "/departamento" },
          { icon: "mdi-airplane-cog", title: "Días festivos", path: "/dias-festivos" },
          { icon: "mdi-calendar-alert", title: "Tipos de Incidencias", path: "/tipos-solicitud" },
          { icon: "mdi-calendar-alert-outline", title: "Subtipos de Incidencias", path: "/subtipos-solicitud" },
          { icon: "mdi-account-details", title: "Incidencias de empleados", path: "/incidencias-empleados" },
        ],
      },
      { icon: "mdi-calendar-cursor", title: "Planificador", path: "/planificador" },
      {
        icon: "mdi-file-document-multiple-outline",
        title: "Reportes",
        children: [
          { icon: "mdi-clock", title: "Tiempo real", path: "/tiempo-real" },
          // { icon: "mdi-calendar-cursor", title: "Solicitud de calendario", path: "/solicitud-calendario" },
          // { icon: "mdi-account-details-outline", title: "Reporte Headcount", path: "/vacaciones" },
          { icon: "mdi-account-details-outline", title: "Reporte de rotación", path: "/reporte-rotacion" },
          { icon: "mdi-calendar", title: "Vacaciones", path: "/vacaciones" },
          { icon: "mdi-progress-close", title: "Faltas", path: "/faltas" },
          { icon: "mdi-hospital-box-outline", title: "incapacidad", path: "/incapacidad" },
          { icon: "mdi-timer-alert-outline", title: "Tiempo extra", path: "/tiempo-extra" },
          { icon: "mdi-file-account-outline", title: "Reporte de personal", path: "/reporte-personal" },
          { icon: "mdi-file-document-alert-outline", title: "Reporte general de incidencias", path: "/visor-archivos" },
          { icon: "mdi-rolodex-outline", title: "Calendario individual", path: "/calendario" },
        ],
      },
      {
        icon: "mdi-cogs",
        title: "Configuración",
        children: [
          { icon: "mdi-cog", title: "Ajustes", path: "/ajustes" },
          { icon: "mdi-devices", title: "Dispositivos", path: "/dispositivos" },
          { icon: "mdi-calendar-star", title: "Días de vacaciones", path: "/dias-vacaciones" },
        ],
      },
      
    ],
  }),
  computed: {
    actualPage() {
      let urls = [];
      for (let route of this.items) {
        if (route.children) {
          for (let route2 of route.children) {
            if (route2.path == this.$route.path) {
              urls.push({ icon: route.icon, title: route.title, path: route.path }, { icon: route2.icon, title: route2.title, path: route2.path });
            }
          }
        } else if (route.path == this.$route.path) {
          urls.push({ icon: route.icon, title: route.title, path: route.path });
        }
      }
      return urls;
    },
  },
  methods: {},
  props: props,
};
</script>

<style scoped></style>
