<template>
  <div>
    <Bread-Crumb v-if="!hideRoute" />
    <v-card class="elevation-5 mt-5 mx-5">
      <v-toolbar dense dark :color="color" elevation="2">
        <v-toolbar-title v-text="title" />
        <v-spacer></v-spacer>
        <slot name="header-buttons" />
        <!--<vue-json-to-csv v-if="exportInfo.length > 0" :json-data="exportInfo" :csv-title="$route.name + dateFormat(new Date())" :labels="exportLabels">
          <v-btn small text>
            Exportar
            <v-icon right>mdi-file-excel-outline</v-icon>
          </v-btn>
        </vue-json-to-csv>-->
        <template v-if="exportInfo.length">
          <v-btn v-if="title === 'Vacaciones'" small text @click="downloadXLSX()">
            Exportar
            <v-icon right>mdi-file-excel-outline</v-icon>
          </v-btn>
          <template v-else>
            <v-btn small text @click="download()">
              Exportar
              <v-icon right>mdi-file-excel-outline</v-icon>
            </v-btn>
            <v-btn small text :loading="loading" @click="downloadPDF()">
              Imprimir
              <v-icon right>mdi-printer</v-icon>
            </v-btn>
          </template>
        </template>
      </v-toolbar>
      <v-card-title class="pb-0">
        <slot name="bottom-header" />
      </v-card-title>
      <v-card-title>
        <slot class="text-center" name="buttons" />
        <v-spacer></v-spacer>
        <slot class="text-center" name="search" />
      </v-card-title>
      <slot></slot>
    </v-card>
  </div>
</template>

<script>
import { BreadCrumb } from "@/components";
import { exportStructure, namesReport } from "@/utils";
import services from "@/utils/services";
import VueJsonToCsv from "vue-json-to-csv";
import moment from "moment";
import downloadjs from 'downloadjs'

const props = {
  title: {
    default: "",
    type: String,
  },
  color: {
    default: "primary",
    type: String,
  },
  hideRoute: {
    default: false,
    type: Boolean,
  },
  exportInfo: {
    default: [],
    type: Array,
  },
  dates: {
    default() { return [] },
    type: Array,
  },
  departments: {
    default() { return [] },
    type: Array,
  },
};

export default {
  components: {
    BreadCrumb,
    VueJsonToCsv,
  },
  props: props,
  data: () => ({
    loading: false
  }),
  computed: {
    exportLabels() {
      return exportStructure[this.$route.name];
    },
  },
  methods: {
    dateFormat(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "Desconocido";
    },
    download() {
      let data = {
        title: (namesReport[this.$route.name]) ? namesReport[this.$route.name] : this.$route.name,
        dates:  this.dates,
        headers: exportStructure[this.$route.name],
        items: this.exportInfo,
        departments: this.departments
      }
      this.axios({
        url: services.routes.reports + '/export',
        method: 'POST',
        data: { data },
        responseType: 'blob'
      })
      .then((response) => {
        let date = moment(new Date).format("YYYY-MM-DD")
        let name = `${this.$route.name} ${date}.xlsx`
        downloadjs(response.data, name, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      })
    },
    downloadPDF() {
      this.loading = true
      let data = {
        title: (namesReport[this.$route.name]) ? namesReport[this.$route.name] : this.$route.name,
        dates:  this.dates,
        headers: exportStructure[this.$route.name],
        items: this.exportInfo,
        departments: this.departments
      }
      this.axios({
        url: services.routes.reports + '/export/pdf',
        method: 'POST',
        data: { data },
        responseType: 'blob'
      })
      .then((response) => {
        let date = moment(new Date).format("YYYY-MM-DD")
        let name = `${this.$route.name} ${date}.pdf`
        downloadjs(response.data, name, "application/pdf");
      })
      .finally(() => {
        this.loading = false
      });
    },
    downloadXLSX() {
      let data = {
        title: (namesReport[this.$route.name]) ? namesReport[this.$route.name] : this.$route.name,
        dates:  this.dates,
        headers: exportStructure[this.$route.name],
        items: this.exportInfo,
      }
      this.axios({
        url: services.routes.reports + '/vacation/xlsx',
        method: 'POST',
        data: { data },
        responseType: 'blob'
      })
      .then((response) => {
        downloadjs(response.data, "Reporte de Vacaciones.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      })
    }
  },
};
</script>

<style scoped>
</style>
