const host = process.env.VUE_APP_API_SERVER;

const routes = {
  user: host + "/api/admin/user",
  role: host + "/api/admin/role",
  department: host + "/api/admin/department",
  position: host + "/api/admin/position",
  requestType: host + "/api/admin/requestType",
  requestSubtype: host + "/api/admin/requestSubType",
  scheduleType: host + "/api/admin/scheduleType",
  setting: host + "/api/admin/setting",
  eventFind: host + "/api/admin/event/find",
  eventCalendar: host + "/api/admin/event/calendar",
  eventDeleteFoul: host + "/api/admin/event/deleteFoul",
  event: host + "/api/admin/event",
  updateInability: host + "/api/admin/event/inability",
  moveRest: host + "/api/admin/event/moveRest",
  requestCalendar: host + "/api/admin/requestCalendar",
  catalog: host + "/api/admin/catalog",
  usersOrDeparment: host + "/api/admin/event/report/by/usersOrDeparment",
  employeeRequest: host + "/api/admin/employee/request",
  reports: host + "/api/admin/reports",
  vacations: host + "/api/admin/vacation",
};

export default {
  routes,
};
