let exportStructure = {
  "Usuarios": {
    id: { title: "ID" },
    nombre_completo: { title: "Nombre" },
    no_empleado: { title: "Numero de empleado" },
    telefono: { title: "Telefono" },
    puesto: { title: "Puesto" },
    departamento: { title: "Departamento" },
    tipo_horario: { title: "Tipo de horario" },
    activo: { title: "Activo" },
  },
  "Departamentos": {
    id: { title: "ID" },
    nombre: { title: "Nombre" },
    jefe: { title: "Coordinador" },
    fecha_creacion: { title: "Fecha de creación" },
    fecha_actualizacion: { title: "Fecha de actualización" },
  },
  "Puestos": {
    id: { title: "ID" },
    nombre: { title: "Nombre" },
    fecha_creacion: { title: "Fecha de creación" },
    fecha_actualizacion: { title: "Fecha de actualización" },
  },
  "Horarios": {
    idtipo_horario: { title: "ID" },
    nombre: { title: "Nombre" },
    comentario: { title: "Comentarios" },
    horario: { title: "Horarios" },
    fecha_creacion: { title: "Fecha de creación" },
  },
  "Tipos de solicitud": {
    id: { title: "ID" },
    nombre: { title: "Nombre" },
    fecha_actualizacion: { title: "Fecha de creación" },
    fecha_creacion: { title: "Fecha de actualización" },
  },
  "Subtipos de solicitud": {
    id: { title: "ID" },
    nombre: { title: "Nombre" },
    tipo_solicitud: { title: "Tipo de solicitud" },
    fecha_actualizacion: { title: "Fecha de creación" },
    fecha_creacion: { title: "Fecha de actualización" },
  },
  "Ajustes": {
    idconfiguracion: { title: "ID" },
    tipo: { title: "Tipo" },
    descripcion: { title: "Descripción" },
  },
  "Días festivos": {
    iddia_festivo: { title: "ID" },
    nombre: { title: "Nombre" },
    fecha_calendario: { title: "Fecha" },
  },
  "Dispositivos": {
    id: { title: "ID" },
    nombre: { title: "Nombre" },
    ip: { title: "IP" },
    puerto: { title: "ID" },
    fecha_creacion: { title: "Fecha de creacion" },
    fecha_actualizacion: { title: "Fecha de actualizacion" },
  },
  "Reporte de personal": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    puesto: { title: "Puesto" },
    departamento: { title: "Departamento" },
    jefe_nombre_completo: { title: "Nombre de coordinador de departamento" },
    fecha_ingreso: { title: "Fecha de ingreso" },
    fecha_nacimiento: { title: "fecha de nacimiento" },
    tipo_horario: { title: "Tipo de horario" },
    talla_playera: { title: "Talla de playera" },
    banco: { title: "Banco" },
    clabe: { title: "Clabe" },
    puesto: { title: "Puesto" },
  },
  "Tiempo real": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    evento: { title: "Evento" },
    departamento: { title: "Departamento" },
    fecha: { title: "Fecha" },
    tiempo_retardo: { title: "Retraso (En minutos)" },
  },
  "Solicitudes de empleados": {
    id: { title: "ID" },
    empleado_solicita: { title: "Empleado" },
    puesto: { title: "Puesto" },
    departamento: { title: "Departamento" },
    jefe: { title: "Coordinador" },
    fecha_creacion: { title: "Fecha de creacion" },
    tipo_solicitud: { title: "Tipo de incidente" },
    subtipo_solicitud: { title: "Subtipo de incidente" },
    estado: { title: "Estado" },
    siguiente_usuario_por_aprobar: { title: "Siguiente aprobador" },
  },
  "Vacaciones": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    evento: { title: "Evento" },
    departamento: { title: "Departamento" },
    fecha: { title: "Fecha" },
    tipo: { title: "Tipo" },
  },
  "Aniversarios": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    departamento: { title: "Departamento" },
    puesto: { title: "Puesto" },
    fecha_ingreso: { title: "Fecha de ingreso" },
    dias_antiguedad: { title: "Días de antiguedad" },
    antiguedad: { title: "Años de antiguedad" },
  },
  "Incapacidades": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    evento: { title: "Evento" },
    departamento: { title: "Departamento" },
    fecha_inicio: { title: "Fecha inicio" },
    fecha_fin: { title: "Fecha fin" },
  },
  "Faltas": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    evento: { title: "Evento" },
    departamento: { title: "Departamento" },
    fecha: { title: "Fecha" },
    tiempo_retardo: { title: "Retraso (En minutos)" },
  },
  "Retardos": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    evento: { title: "Evento" },
    departamento: { title: "Departamento" },
    fecha: { title: "Fecha" },
    tiempo_retardo: { title: "Retraso (En minutos)" },
  },
  "Omitio Entrada": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    evento: { title: "Evento" },
    departamento: { title: "Departamento" },
    fecha: { title: "Fecha" },
    tiempo_retardo: { title: "Retraso (En minutos)" },
  },
  "Tiempo extra": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    nombre_puesto: { title: "Puesto" },
    nombre_departamento: { title: "Departamento" },
    nombre_horario: { title: "Horario" },
    sum_end: { title: "Horas Extra" },
    sum_start: { title: "Horas de retrasos" },
    sum: { title: "Total Horas Extras" },
    sum_consumo: { title: "Solicitudes tiempo extra (horas)" },
  },
  "Registros": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    nombre_puesto: { title: "Puesto" },
    nombre_departamento: { title: "Departamento" },
    nombre_horario: { title: "Horario" },
  },
  "Reporte de rotación": {
    iddepartamento: { title: "ID departamento" },
    nombre_departamento: { title: "Nombre de departamento" },
    idusuario: { title: "ID de usuario" },
    nombre_completo_jefe: {title: "Nombre de jefe" },
    activos_inicio_periodo: { title: "Activos al inicio del periodo" },
    activos_final_periodo: { title: "Activos al final del periodo" },
    renuncias_periodo: { title: "Renuncias del periodo" },
    indice_rotacion: { title: "Indice de rotación(%)" },
  },
  "Incidencias de empleados": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    evento: { title: "Evento" },
    departamento: { title: "Departamento" },
    fecha: { title: "Fecha" },
    hora: { title: "Hora" },
    tiempo_retardo: { title: "Tiempo de retardo" },
  },
  "Días de vacaciones": {
    antiguedad: { title: "Año de antiguedad" },
    dias_vacaciones: { title: "Días de vacaciones" },
    dias_adelantos: { title: "Días adelantar" },
  },
  "Captura de vacaciones": {
    no_empleado: { title: "Numero de empleado" },
    nombre_completo: { title: "Nombre" },
    puesto: { title: "Puesto" },
    departamento: { title: "Departamento" },
    dias_periodo_actual: { title: "Total de días correspondientes" },
    dias_tomados: { title: "Días tomados" },
    saldo: { title: "Días pendientes por disfrutar" },
    antiguedad: { title: "Años de antigüedad" },
  },
};

export default exportStructure;
