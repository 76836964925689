import Vue from "vue";
import Router from "vue-router";
import auth from "@/router/auth";

Vue.use(Router);

function checkSession(to, from, next) {
  if (Vue.prototype.$session.exists()) {
    auth.check().then((res) => {
      if (res) {
        if (to.params.pantalla) {
          next("/" + to.params.pantalla + "/" + to.params.codigo);
        } else {
          next();
        }
      } else {
        next("/login");
      }
    });
  } else {
    next("/login");
  }
}

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      component: () => import("@/pages/errors/404.vue"),
    },
    {
      path: "/login",
      name: "Login",
      component: () => import(`@/pages/Login.vue`),
      beforeEnter(to, from, next) {
        if (Vue.prototype.$session.exists()) {
          auth.check().then((res) => {
            if (res) {
              if (to.query.pantalla) {
                next("/" + to.query.pantalla + "/" + to.query.codigo);
              } else {
                next("/home");
              }
            } else {
              next();
            }
          });
        } else {
          next();
        }
      },
    },
    {
      path: "/reset/password/:id",
      name: "Reset",
      component: () => import(`@/pages/Reset.vue`),
    },
    {
      path: "/",
      name: "Root",
      redirect: {
        name: "Home",
      },
      component: () => import(`@/pages/Root.vue`),
      children: [
        // Root
        {
          path: "/home",
          name: "Home",
          component: () => import(`@/pages/Home.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/visor-archivos",
          name: "Visor de archivos",
          component: () => import(`@/pages/Documents.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/solicitud-calendario",
          name: "Solicitud de calendario",
          component: () => import(`@/pages/RequestCalendar.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/planificador",
          name: "Planificador",
          component: () => import(`@/pages/ConsolidatedCalendar.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/pasos-planificador",
          name: "Pasos planificador",
          component: () => import(`@/pages/Planner.vue`),
          beforeEnter: checkSession,
        },
        // Solicitudes
        {
          path: "/solicitud-empleados",
          name: "Solicitudes de empleados",
          component: () => import(`@/pages/requests/EmployeeRequest.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/nueva-solicitud-empleados",
          name: "Nueva solicitud de empleados",
          component: () => import(`@/pages/requests/NewEmployeeRequest.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/detalle-solicitud-empleados/:id",
          name: "Detalle solicitud de empleados",
          component: () => import(`@/pages/requests/DetailEmployeeRequest.vue`),
          beforeEnter: checkSession,
        },
        // Recursos humanos
        {
          path: "/usuario",
          name: "Usuarios",
          component: () => import(`@/pages/human-resource/User.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/departamento",
          name: "Departamentos",
          component: () => import(`@/pages/human-resource/Department.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/puesto",
          name: "Puestos",
          component: () => import(`@/pages/human-resource/Position.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/horarios",
          name: "Horarios",
          component: () => import(`@/pages/human-resource/Schedule.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/tipos-solicitud",
          name: "Tipos de solicitud",
          component: () => import(`@/pages/human-resource/RequestType.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/subtipos-solicitud",
          name: "Subtipos de solicitud",
          component: () => import(`@/pages/human-resource/RequestSubtype.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/dias-festivos",
          name: "Días festivos",
          component: () => import(`@/pages/human-resource/Holiday.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/captura-vacaciones",
          name: "Captura de vacaciones",
          component: () => import(`@/pages/human-resource/Vacation.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/incidencias-empleados",
          name: "Incidencias de empleados",
          component: () => import(`@/pages/human-resource/EmployeeIncidents.vue`),
          beforeEnter: checkSession,
        },
        // Reportes
        {
          path: "/tiempo-real",
          name: "Tiempo real",
          component: () => import(`@/pages/reports/RealTime.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/calendario",
          name: "Calendario",
          component: () => import(`@/pages/reports/Calendar.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/reporte-personal",
          name: "Reporte de personal",
          component: () => import(`@/pages/reports/PersonalReport.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/vacaciones",
          name: "Vacaciones",
          component: () => import(`@/pages/reports/Vacations.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/aniversarios",
          name: "Aniversarios",
          component: () => import(`@/pages/reports/Anniversary.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/faltas",
          name: "Faltas",
          component: () => import(`@/pages/reports/Events.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/retardos",
          name: "Retardos",
          component: () => import(`@/pages/reports/Events.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/omitio-entrada",
          name: "Omitio Entrada",
          component: () => import(`@/pages/reports/Events.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/incapacidad",
          name: "Incapacidades",
          component: () => import(`@/pages/reports/Inability.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/tiempo-extra",
          name: "Tiempo extra",
          component: () => import(`@/pages/reports/ExtraTime.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/reporte-registros",
          name: "Registros",
          component: () => import(`@/pages/reports/Record.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/reporte-rotacion",
          name: "Reporte de rotación",
          component: () => import(`@/pages/reports/Turnover.vue`),
          beforeEnter: checkSession,
        },
        // Ajustes
        {
          path: "/ajustes",
          name: "Ajustes",
          component: () => import(`@/pages/settings/Settings.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/dispositivos",
          name: "Dispositivos",
          component: () => import(`@/pages/settings/Devices.vue`),
          beforeEnter: checkSession,
        },
        {
          path: "/dias-vacaciones",
          name: "Días de vacaciones",
          component: () => import(`@/pages/settings/VacationDays.vue`),
          beforeEnter: checkSession,
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  next();
});

router.afterEach((to, from) => { });

export default router;
